import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["event", "emptyLabel"]
  static classes = ["eventHidden", "emptyLabelHidden"]
  
  eventTargetConnected(event) {
    if (Date.parse(event.dataset.date) >= Date.now()) {
      event.classList.remove(this.eventHiddenClass)
      this.emptyLabelTarget.classList.add(this.emptyLabelHiddenClass)
    }
  }
}