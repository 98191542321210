import { Controller } from "@hotwired/stimulus"

import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"

export default class extends Controller {
  static targets = ["nextButton", "prevButton", "pagination"]

  static values = {
    autoplay: {
      type: Boolean,
      default: false
    }
  }

  slider = null

  connect() {
    this.slider = new Swiper(this.element, {
      modules: [Navigation, Pagination, Autoplay, EffectFade],
      
      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
      
      autoplay: {
        enabled: this.autoplayValue,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        delay: 5000,
      },
      loop: true,
      speed: 1000,
      
      navigation: {
        nextEl: this.hasNextButtonTarget ? this.nextButtonTarget : null,
        prevEl: this.hasPrevButtonTarget ? this.prevButtonTarget : null,
      },
      pagination: {
        el: this.hasPaginationTarget ? this.paginationTarget : null,
        type: "bullets",
        clickable: true,
      },
      
      grabCursor: true
    })
  }

  disconnect() {
    this.slider.destroy({ completely: true })
    this.slider = null
  }
}
