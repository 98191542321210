import { Controller } from "@hotwired/stimulus"

import Swiper, { EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"

export default class extends Controller {
  static targets = ["text", "button", "slider"]
  static classes = ["textHidden"]
  
  slider = null
  
  connect() {
    this.slider = new Swiper(this.sliderTarget, {
      modules: [EffectFade],
      
      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
      
      loop: true,
      speed: 1000,
      
      keyboard: false,
      allowTouchMove: false,
      mousewheel: false,
      grabCursor: false,
    })
  }
  
  disconnect() {
    this.slider.destroy()
    this.slider = null
  }
  
  slideTo({ params }) {
    this.buttonTargets.forEach((button, index) => {
      if (index == params.index) {
        button.dataset.current = true
      } else {
        button.dataset.current = false
      }
    })
    
    this.slider.slideTo(params.index)
    
    this.textTargets.forEach((text, index) => {
      if (index == params.index) {
        text.classList.remove(this.textHiddenClass)
      } else {
        text.classList.add(this.textHiddenClass)
      }
    })
  }
}