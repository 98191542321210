import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    open: Boolean
  }
  
  toggle() {
    this.openValue = !this.openValue
    
    if (this.openValue == true) {
      window.scrollTo(0,0)
      document.body.classList.add("overflow-hidden")
    } else {
      document.body.classList.remove("overflow-hidden")
    }
  }
}